// Button animation
const button = document.querySelector('.btn-submit') as HTMLElement;
button.addEventListener('click', () => {
	button.classList.add('btn-loading');
});

// Handle form input animation
const inputs = document.querySelectorAll<HTMLInputElement>('.form-control');

const setFilled = (input: HTMLInputElement) => {
	const parent = input.parentElement as Element;
	if (input.value !== '') {
		parent.classList.add('filled');
	} else {
		parent.classList.remove('filled');
	}
};

for (const input of inputs) {
	setFilled(input);

	input.addEventListener('change', (event) => {
		const input = event.currentTarget as HTMLInputElement;
		setFilled(input);
	});

	input.addEventListener('focus', (event) => {
		const input = event.currentTarget as HTMLInputElement;
		const parent = input.parentElement as Element;
		parent.classList.add('focused');
	});

	input.addEventListener('blur', (event) => {
		const input = event.currentTarget as HTMLInputElement;
		const parent = input.parentElement as Element;
		parent.classList.remove('focused');
	});
}

export {};
